import { AxiosError } from 'axios';
import React, { FC } from 'react';
import { CheckCircle, XOctagon } from 'react-feather';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';

import { Button } from '../components/Button';
import { Input } from '../components/Input';
import { H4 } from '../components/Typography';
import { forgotPassword as forgotPasswordAction } from '../lib/actions/me';
import { getBodyMessage } from '../utils/function-utils';
import { Container, HelpText } from './commonStyles';

export const ForgotPasswordForm: FC = () => {
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    reset,
  } = useForm();
  const dispatch = useDispatch();

  const onSubmit = async (values: { email: string }) => {
    try {
      await dispatch(forgotPasswordAction(values.email));
      toast.success('Password reset request was sent successfully, please check your email', {
        icon: <CheckCircle className="w-8" size={32} color="#3CB043" />,
      });
      reset();
    } catch (err) {
      toast.error(getBodyMessage(err as AxiosError), {
        icon: <XOctagon className="w-8" size={32} color="#D0312D" />,
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="relative">
      <Container className="flex flex-col flex-1 bg-white w-full">
        <H4 className="mb-6 text-center">Forgot Password</H4>

        <Input
          label="Email"
          className="mb-4"
          error={!!errors.email}
          helpText={
            errors.email && (
              <HelpText>{errors.email?.type === 'required' ? 'Email is required' : 'Email is invalid'}</HelpText>
            )
          }
          inputStyle={{ color: '#333' }}
          {...register('email', {
            required: true,
            pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
          })}
        />

        <div className="flex justify-end mt-4">
          <Button type="submit" disabled={isSubmitting}>
            Submit
          </Button>
        </div>
      </Container>
    </form>
  );
};

import { RouteComponentProps } from '@reach/router';
import React, { FC } from 'react';

import { CaseCount } from '../components/CaseCount';
import { DefaultContent } from '../components/DefaultContent';
import { Hero, HeroBackground } from '../components/Hero';
import { PublicRoute } from '../components/PublicRoute';
import { SEO } from '../components/Seo';
import { ForgotPasswordForm } from '../forms/ForgotPasswordForm';
import { Title } from '../styles/common';

const ForgotPassword: FC<RouteComponentProps> = (props) => {
  return (
    <PublicRoute {...props}>
      <SEO title="Forgot Password" description="Recover your Hypercite passwork here" />
      <Hero className="flex flex-col items-center w-full">
        <Title className="text-center mt-16 mb-12">
          Automatically Hyperlink Your Citations to the ROA, Transcripts, Prior Briefing, and More in Just Seconds
        </Title>
        <HeroBackground />
        <div className="w-full flex px-4">
          <div className="w-full md:w-2/3 lg:w-1/2 ml-auto mr-auto">
            <ForgotPasswordForm />
          </div>
        </div>

        <CaseCount />
      </Hero>

      <DefaultContent location={props.location} />
    </PublicRoute>
  );
};

export default ForgotPassword;
